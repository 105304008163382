import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useStore } from '@app/stores/root/helpers/storeContext';
import { INVALID_CREDENTIALS_ERROR } from '@app/stores/user/actions';

import { AuthFormDiv, Title, Image, Label, Error } from './styled';

export const AuthForm = () => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [fields, setFields] = useState<{
    [key: string]: any;
  }>({});
  const [authError, setError] = useState<string | null>(null);

  const { user } = useStore();
  const router = useRouter();

  useEffect(() => {
    if (user.inited && user.isAuth) {
      router.reload();
    }
  }, [user.inited, user.isAuth]);

  const updateField = (name: string, value: string) => {
    const newValue = {};
    newValue[name] = value;

    setFields(Object.assign({}, fields, newValue));
  };

  const handleChange = event => {
    if (authError) {
      setError(null);
    }

    updateField(event.target.name, event.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (formSubmitted) {
      return false;
    }

    setFormSubmitted(true);

    await user
      .login(fields as any)
      .then(res => {
        if (res === INVALID_CREDENTIALS_ERROR) {
          setError('Неверные логин/пароль');
        } else {
          router.push('/');
        }
      })
      .catch(error => {
        console.log('err', error);
        setError(error.message);
      })
      .finally(() => {
        setFormSubmitted(false);
      });
  };

  return (
    <AuthFormDiv onSubmit={handleSubmit} method="post">
      <Image src="/favicon.svg" />
      <Title>Войти</Title>
      <Error>{authError}</Error>
      <Label key="auth_phone">
        <input
          placeholder="Телефон (example: 79991234567)"
          type="number"
          name="phone"
          required={true}
          onChange={handleChange}
          value={fields['phone']}
        />
      </Label>
      <Label key="auth_password">
        <input
          placeholder="Пароль"
          type="password"
          name="password"
          required={true}
          onChange={handleChange}
          value={fields['password']}
        />
      </Label>
      <button className="primary">Войти</button>
    </AuthFormDiv>
  );
};
