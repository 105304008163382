import { get } from 'lodash-es';
import { flow } from 'mobx-state-tree';
import { Option } from 'tsoption';

import { canUseDOM } from '@app/core/helpers/canUseDom';
import {
  getTokenFromCookie,
  removeTokenToCookie,
  setTokenToCookie,
} from '@app/core/helpers/setCookie';
import { getFromConfig } from '@app/core/libs/getPublicRuntimeConfig';
import { getApiService, getEnv } from '@app/stores/root/helpers/getEnv';
import { SelfUserStore } from '@app/stores/user/index';
import { AsyncFunc, AsyncFuncWithParam } from '@app/types/AsyncFunc';
import { User, UserStatus } from '@app/types/User';
import { apiErrorHandler } from '@app/service/api/helper';

import { getRoot } from '../root/helpers/getRoot';

const testing = getFromConfig('testing');

const initializeUserCredentials = (self: SelfUserStore) => () => {
  if (canUseDOM()) {
    const token = getTokenFromCookie();

    if (!!token) self.setToken(Option.of(token));
  }
};

export const INVALID_CREDENTIALS_ERROR = 'Invalid credentials';

const login = (
  self: SelfUserStore,
): AsyncFuncWithParam<{ phone: string; password: string }, any> =>
  flow(function* fetch({ phone, password }) {
    try {
      if (getTokenFromCookie()) {
        console.log('token in cookies');
        return;
      }
      const token: any = yield getApiService(self).request(
        'post',
        '/auth/login',
        {
          phone,
          password,
        },
      );
      self.setToken(Option.of(token));
      getEnv(self).api.setHeader('Authorization', `Bearer ${token}`);

      yield self.loadUser({ force: true, skipLoading: true });
    } catch (error) {
      console.error(error);
      if (get(error, 'data.message') === INVALID_CREDENTIALS_ERROR) {
        return INVALID_CREDENTIALS_ERROR;
      }

      throw error;
    }
  });

const signOut = (self: SelfUserStore): AsyncFunc<any, void> =>
  flow(function*() {
    try {
      removeTokenToCookie();
      getEnv(self).api.deleteHeader('Authorization');

      yield self.loadUser({ force: true });
    } catch (error) {
      console.error(error);
    }
  });

const loadUser = (
  self: SelfUserStore,
): AsyncFunc<{ force?: boolean; skipLoading?: boolean }, any> =>
  flow(function* fetch(params = { force: false, skipLoading: false }) {
    const { force, skipLoading } = params;

    try {
      if (self._data && !force) return;
      if (!skipLoading) {
        self.loading = true;
      }

      const user: any = yield getApiService(self).request('get', '/user');

      self._data = user as User;
    } catch (error) {
      console.error(error);
      //return apiErrorHandler(error);
    } finally {
      self.loading = false;
      self.setInited();
    }
  });

const updateUser = (self: SelfUserStore): AsyncFunc<any, any> =>
  flow(function* fetch(params) {
    try {
      yield getApiService(self).request('put', '/user/update', params);
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const editUser = (self: SelfUserStore): AsyncFunc<any, any> =>
  flow(function* fetch(params) {
    try {
      const user = yield getApiService(self).request(
        'put',
        '/user/edit',
        params,
      );

      self._list.set(user.id, user);
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const addUser = (self: SelfUserStore): AsyncFunc<any, any> =>
  flow(function* fetch(params) {
    try {
      const user = yield getApiService(self).request(
        'post',
        '/user/create',
        params,
      );

      self._list.set(user.id, user);

      return user;
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const loadList = (self: SelfUserStore): AsyncFunc<any, any> =>
  flow(function* fetch() {
    try {
      const res = yield getApiService(self).request('get', `/user/list`);

      res.map(item => self._list.set(item.id, item));
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const loadById = (self: SelfUserStore): AsyncFuncWithParam<any, any> =>
  flow(function* fetch(id: string) {
    try {
      if (!self._list.get(id)) {
        const res = yield getApiService(self).request('get', `/user/${id}`);

        self._list.set(res.id, res);
      }
    } catch (error) {
      return apiErrorHandler(error);
    }
  });

const setToken = (self: SelfUserStore) => (token: Option<string>) => {
  getEnv(self).api.setHeader('Authorization', 'Bearer ' + token.getOrElse(''));
  setTokenToCookie(token.getOrElse(''));
};

const setInited = (self: SelfUserStore) => () => {
  self._inited = true;
};

export const Actions = self => ({
  setToken: setToken(self),
  loadUser: loadUser(self),
  initializeUserCredentials: initializeUserCredentials(self),
  login: login(self),
  signOut: signOut(self),
  setInited: setInited(self),
  updateUser: updateUser(self),
  editUser: editUser(self),
  addUser: addUser(self),
  loadList: loadList(self),
  loadById: loadById(self),
});
