import styled from 'styled-components';

export const AuthFormDiv = styled.form`
  display: block;
  text-align: center;
  width: 100%;
`;

export const Error = styled.span`
  color: red;
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`;

export const Image = styled.img`
  width: 57px;
  height: auto;
  margin-left: calc(45%);
  margin-bottom: 58px;
`;

export const Label = styled.label`
  margin: 20px 0;
  display: block;

  & input {
    text-align: center;
    width: 100%;
    border: 1px solid #a1a5b3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 56px;
  }
`;
