import Cookies from 'js-cookie';

import { getFromConfig } from '../libs/getPublicRuntimeConfig';

const isDev = getFromConfig('isDev');

const sameSiteAttrs: any = isDev ? {} : { sameSite: 'none', secure: true };

const TOKEN_KEY = 'csi_token';
const TOKEN_EXPIRES = 365;

export const setTokenToCookie = (token: string) =>
  Cookies.set(TOKEN_KEY, token, { expires: TOKEN_EXPIRES, ...sameSiteAttrs });
export const removeTokenToCookie = () => Cookies.remove(TOKEN_KEY);
export const getTokenFromCookie = () => Cookies.get(TOKEN_KEY);
